import { useCallback, useEffect, useState } from "react";
import { useFetch } from "./hooks/useFetch";
import API_CONFIG from "./config";
import axios from "axios";

export const useHomePageData = () => {
  const { data, loading } = useFetch("/homepage");

  return {
    loading,
    data,
  };
};

export const usePostsData = () => {
  const { data, loading } = useFetch("/posts");

  return {
    loading,
    data,
  };
};

export const useCategoryData = () => {
  const { data, loading } = useFetch("/categories");

  return {
    loading,
    data,
  };
};

export const useSoftwarePageData = () => {
  const { data, loading } = useFetch("/software");

  return {
    loading,
    data,
  };
};

export const useAboutPageData = () => {
  const { data, loading } = useFetch("/about");

  return {
    loading,
    data,
  };
};

export const useCalculatorPageData = () => {
  const { data, loading } = useFetch("/calculator");

  return {
    loading,
    data,
  };
};

export const useContactPageData = () => {
  const { data, loading } = useFetch("/contact");

  return {
    loading,
    data,
  };
};

// fetching data for services page
export const useGovernancePageData = () => {
  const { data, loading } = useFetch("/governance");

  return {
    loading,
    data,
  };
};

export const useMatchingPageData = () => {
  const { data, loading } = useFetch("/matching");

  return {
    loading,
    data,
  };
};

export const useCompliancePageData = () => {
  const { data, loading } = useFetch("/compliance-processing");

  return {
    loading,
    data,
  };
};

export const useDataSciencePageData = () => {
  const { data, loading } = useFetch("/data-science");

  return {
    loading,
    data,
  };
};

export const useOutsourcingPageData = () => {
  const { data, loading } = useFetch("/navigation-outsourcing");

  return {
    loading,
    data,
  };
};

export const useServicesData = () => {
  const { data, loading } = useFetch("/services");

  return {
    data,
    loading,
  };
};

export const useGlobalData = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const fn = useCallback(async function () {
    try {
      // setLoading(true);
      const footerResponse = await axios.get(
        `${API_CONFIG.baseURL}/api/footer?populate=*`
      );

      const globalResponse = await axios.get(
        `${API_CONFIG.baseURL}/api/global?populate=*`
      );

      setData({
        global: globalResponse.data.data.attributes,
        footer: footerResponse.data.data.attributes,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fn();
  }, []);

  return {
    loading,
    data,
  };
};

// fetching data for legal pages
export const usePrivacyPolicyPageData = () => {
  const { data, loading } = useFetch("/privacy-policy");

  return {
    loading,
    data,
  };
};

export const useDisclaimerPageData = () => {
  const { data, loading } = useFetch("/disclaimer");

  return {
    loading,
    data,
  };
};

export const useTNCPageData = () => {
  const { data, loading } = useFetch("/terms-and-condition");

  return {
    loading,
    data,
  };
};

export const useSubCategoryData = () => {
  const { data, loading } = useFetch("/sub-categories");

  return {
    loading,
    data,
  };
};

export const useRetentionRates = () => {
  const { data, loading } = useFetch("/retention-rates");

  return {
    ratesLoading: loading,
    ratesData: data,
  };
};

export const useScienceData = () => {
  const { data, loading } = useFetch("/science");

  return {
    loading,
    data,
  };
};

export const useCaseStudyData = () => {
  const { data, loading } = useFetch("/case-study");

  return {
    loading,
    data,
  };
};
