import React, { lazy, Suspense, useEffect } from "react";
import "./styles/main.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/layout/Layout";
// import HomePage from "./pages/HomePage";
// import About from "./pages/About";
// import Contact from "./pages/Contact";
// import Product from "./pages/Product";
// import PrivacyPolicy from "./pages/PrivacyPolicy";
// import TermsConditions from "./pages/TermsConditions";
// import Disclaimer from "./pages/Disclaimer";
// import Calculator from "./pages/SavingsCalculator";
// import CaseStudy from "./pages/CaseStudy";
// import CaseStudies from "./pages/CaseStudies";
// import Science from "./pages/Science";
// import ScienceTemplate from "./pages/ScienceTemplate";

// import NavigationOutsourcing from "./pages/services/NavigationOutsourcing";
// import Matching from "./pages/services/Matching";
// import Governance from "./pages/services/Governance";
// import DataScience from "./pages/services/DataScience.jsx";
// import Compliance from "./pages/services/Compliance";
// import Sitemap from "./pages/Sitemap.jsx";
import GlobalContextProvider from "./globalContext.js";
import NotFound from "./pages/NotFound";
import Loading from "./components/Loading.jsx";
import ScrollToTop from "./components/common/ScrollToTop.jsx";
const HomePage = lazy(() => import("./pages/HomePage"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Product = lazy(() => import("./pages/Product"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const TermsConditions = lazy(() => import("./pages/TermsConditions"));
const Disclaimer = lazy(() => import("./pages/Disclaimer"));
const Calculator = lazy(() => import("./pages/SavingsCalculator"));
const Sitemap = lazy(() => import("./pages/Sitemap"));
const CaseStudy = lazy(() => import("./pages/CaseStudy"));
const CaseStudies = lazy(() => import("./pages/CaseStudies"));
const Science = lazy(() => import("./pages/Science"));
const ScienceTemplate = lazy(() => import("./pages/ScienceTemplate"));
const Compliance = lazy(() => import("./pages/services/Compliance"));
const DataScience = lazy(() => import("./pages/services/DataScience"));
const Governance = lazy(() => import("./pages/services/Governance"));
const Matching = lazy(() => import("./pages/services/Matching"));
const NavigationOutsourcing = lazy(() =>
  import("./pages/services/NavigationOutsourcing")
);

const useDocumentTitle = (title) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <GlobalContextProvider>
        <Layout>
          <Routes>
            <Route
              path="/"
              element={
                <Suspense fallback={<Loading />}>
                  <HomePage />
                </Suspense>
              }
            />
            <Route
              path="/about-us"
              element={
                <Suspense fallback={<Loading />}>
                  <About />
                </Suspense>
              }
            />
            <Route
              path="/contact-us"
              element={
                <Suspense fallback={<Loading />}>
                  <Contact />
                </Suspense>
              }
            />
            <Route
              path="/product"
              element={
                <Suspense fallback={<Loading />}>
                  <Product />
                </Suspense>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <Suspense fallback={<Loading />}>
                  <PrivacyPolicy />
                </Suspense>
              }
            />
            <Route
              path="/terms-conditions"
              element={
                <Suspense fallback={<Loading />}>
                  <TermsConditions />
                </Suspense>
              }
            />
            <Route
              path="/disclaimer"
              element={
                <Suspense fallback={<Loading />}>
                  <Disclaimer />
                </Suspense>
              }
            />

            <Route
              path="/calculator"
              element={
                <Suspense fallback={<Loading />}>
                  <Calculator />
                </Suspense>
              }
            />

            <Route
              path="/case-studies/:postSlug"
              element={
                <Suspense fallback={<Loading />}>
                  <CaseStudy />
                </Suspense>
              }
            />
            <Route
              path="/case-studies"
              element={
                <Suspense fallback={<Loading />}>
                  <CaseStudies />
                </Suspense>
              }
            />
            <Route
              path="/science"
              element={
                <Suspense fallback={<Loading />}>
                  <Science />
                </Suspense>
              }
            />
            <Route
              path="/science/:postSlug"
              element={
                <Suspense fallback={<Loading />}>
                  <ScienceTemplate />
                </Suspense>
              }
            />
            <Route
              path="/services/navigation-outsourcing"
              element={
                <Suspense fallback={<Loading />}>
                  <NavigationOutsourcing />
                </Suspense>
              }
            />
            <Route
              path="/services/matching"
              element={
                <Suspense fallback={<Loading />}>
                  <Matching />
                </Suspense>
              }
            />
            <Route
              path="/services/governance"
              element={
                <Suspense fallback={<Loading />}>
                  <Governance />
                </Suspense>
              }
            />
            <Route
              path="/services/data-science"
              element={
                <Suspense fallback={<Loading />}>
                  <DataScience />
                </Suspense>
              }
            />
            <Route
              path="/services/compliance"
              element={
                <Suspense fallback={<Loading />}>
                  <Compliance />
                </Suspense>
              }
            />
            <Route
              path="/sitemap"
              element={
                <Suspense fallback={<Loading />}>
                  <Sitemap />
                </Suspense>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </GlobalContextProvider>
    </Router>
  );
};

export default App;
